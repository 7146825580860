exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-talk-js-content-file-path-src-content-essays-2021-03-15-practical-divination-content-mdx": () => import("./../../../src/templates/talk.js?__contentFilePath=/opt/build/repo/src/content/essays/2021-03-15-practical-divination/content.mdx" /* webpackChunkName: "component---src-templates-talk-js-content-file-path-src-content-essays-2021-03-15-practical-divination-content-mdx" */),
  "component---src-templates-talk-js-content-file-path-src-content-essays-2021-06-27-on-masks-content-mdx": () => import("./../../../src/templates/talk.js?__contentFilePath=/opt/build/repo/src/content/essays/2021-06-27-on-masks/content.mdx" /* webpackChunkName: "component---src-templates-talk-js-content-file-path-src-content-essays-2021-06-27-on-masks-content-mdx" */),
  "component---src-templates-talk-js-content-file-path-src-content-essays-2022-05-24-apprenticeship-content-mdx": () => import("./../../../src/templates/talk.js?__contentFilePath=/opt/build/repo/src/content/essays/2022-05-24-apprenticeship/content.mdx" /* webpackChunkName: "component---src-templates-talk-js-content-file-path-src-content-essays-2022-05-24-apprenticeship-content-mdx" */),
  "component---src-templates-talk-js-content-file-path-src-content-food-everything-inside-bagels-content-mdx": () => import("./../../../src/templates/talk.js?__contentFilePath=/opt/build/repo/src/content/food/everything-inside-bagels/content.mdx" /* webpackChunkName: "component---src-templates-talk-js-content-file-path-src-content-food-everything-inside-bagels-content-mdx" */),
  "component---src-templates-talk-js-content-file-path-src-content-presentations-2019-abstractions-content-mdx": () => import("./../../../src/templates/talk.js?__contentFilePath=/opt/build/repo/src/content/presentations/2019-abstractions/content.mdx" /* webpackChunkName: "component---src-templates-talk-js-content-file-path-src-content-presentations-2019-abstractions-content-mdx" */)
}

